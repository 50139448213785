import * as React from "react"
import VTO from "../components/vto/vto"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/vto-style.css"
import { organizationSchema } from "../const/schemas"
import { languages } from "../const/languages"

const ServicesPage = () => <VTO lang={languages.EN}/>

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Virtual try-on solution for multiple platforms"
        description="Revolutionize your customers' virtual shopping experience with Virtual Try-on solution from AR-Labs.io on Android & iOS smartphones and tablets, integrate it into your website or use it with digital displays."
        canonicalURL="https://ar-labs.io/vto"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage
